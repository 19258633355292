<template>
  <div ref="wrapper">
    <slot />
  </div>
</template>

<script setup lang="ts">
import { gsap } from 'gsap'
import { onMounted, ref } from 'vue'

const props = defineProps<{
  from?: gsap.TweenVars
  to?: gsap.TweenVars
}>()

const wrapper = ref(null)

onMounted(() => {
  if (props.from) {
    gsap.from(wrapper.value, props.from)
  }

  if (props.to) {
    gsap.to(wrapper.value, props.to)
  }
})
</script>
